import browserUpdate from 'browser-update/update.npm';
import 'focus-visible/dist/focus-visible'; // Auto-executing, see : https://github.com/WICG/focus-visible
import svg4everybody from 'svg4everybody';

// See : https://github.com/browser-update/browser-update/wiki/Details-on-configuration
browserUpdate({
  required: {
    e: -2, // Edge
    f: -2, // Firefox
    s: -2, // Safari
    c: -2, // Chrome
  },
  insecure: true,
});

// See : https://github.com/jonathantneal/svg4everybody
svg4everybody();
